<template>
  <v-container fluid>
    <v-card class="pa-2 ma-1">
      <v-toolbar
        dense
        floating
        class="mt-n4 ml-n4"
        dark
        color="indigo darken-4"
      >
        <!-- Checkboxes -->
        <v-checkbox
          class="mx-1 mt-4 pt-2"
          v-model="lifecycle"
          label="Drilling"
          value="1"
          @change="updatefiltre"
        ></v-checkbox>
        <v-checkbox
          class="mx-1 mt-4 pt-2"
          ref="vOperating"
          v-model="lifecycle"
          label="Operating"
          value="2"
          @change="updatefiltre"
        ></v-checkbox>
        <v-checkbox
          class="mx-1 mt-4 pt-2"
          v-model="lifecycle"
          label="P&A"
          value="3"
          @change="updatefiltre"
        ></v-checkbox>

        <!-- Vertical Divider -->
        <v-divider vertical class="mx-4"></v-divider>

        <!-- Spacer -->
        <v-spacer></v-spacer>

        <!-- Buttons with icons for view modes -->
        <v-btn v-if="viewlist == true" text @click="changeview">
          <v-icon class="mr-2">mdi-map-marker</v-icon>
          View Map mode
        </v-btn>
        <v-btn v-if="viewlist == false" text @click="changeview">
          <v-icon class="mr-2">mdi-view-list</v-icon>
          View List mode
        </v-btn>

        <!-- Search field -->
        <v-text-field
          v-if="viewlist == false"
          autocomplete="off"
          hide-details
          single-line
          solo-inverted
          clearable
          dense
          flat
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          class="ml-6"
        >
        </v-text-field>
      </v-toolbar>
      <v-card-text>
        <v-row justify="start" align="center">
          <v-col cols="12" sm="12" md="12">
            <listitemspage
              v-if="viewlist == true"
              :selitem="selitem"
              :qSelect="Qselect"
              :qSelectall="Qselectall"
              :qDelete="Qdelete"
              :PageResponse="'wellsstates'"
              :AllResponse="'allwellsstates'"
              :title="'Wells List'"
              :headers="headers"
              :field="'WELLNAME'"
              :field_list="['ANOMALY', 'WELLNAME']"
              :filename="'Wells'"
              :sheetname="'Wells'"
              :showedit="false"
              :btn1="'Advanced Filter'"
              :btn2="unfilter ? 'X' : ''"
              @click1="OpenFilterForm"
              @click2="unfiltrer"
              @open="OpenWellForm"
              @rowselect="WellChange"
              :chip_color="'red'"
              :del_disable="
                well.statut_id > 1 || well.testcount > 0 || well.actioncount > 0
              "
              :Add="editer"
              :del="(editer && well.statut_id == 1) || valider"
              :list_options="false"
              :key="klist"
              :search_elm="filtre"
              :load="loading"
              :CatScope="$store.state.fields"
              :FilterScope="wells_filter"
              :Type="lifecycle"
              :ipg="5"
              @contextmenu="contextmenu"
              @col_btn1_click="WellFailuresOpen"
            >
            </listitemspage>
            <GoogleMap
              v-if="viewlist == false"
              :search="search"
              :lifecycle="lifecycle"
              :list_key="list_key"
              @open="OpenWellForm"
            />
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="12"
            v-if="viewlist && well.id > 0"
            class="stickycard"
          >
            <v-card flat :loading="data_loading">
              <v-card-title primary-title class="justify-center">
                <h2>{{ well.wellname }}</h2>
              </v-card-title>
              <v-card-text>
                <v-tabs v-model="tab0" class="my-tabs">
                  <v-tab key="1" href="#1" class="outlined-button">
                    WII Tests & Analyses
                  </v-tab>
                  <v-tab key="2" href="#2" class="outlined-button">
                    Tubulars
                    <v-badge
                      :color="'purple'"
                      class="mb-1 ml-1"
                      v-if="tubulars_list.length > 0"
                    >
                      <template v-slot:badge>
                        {{ tubulars_list.length }}
                      </template>
                    </v-badge>
                  </v-tab>
                  <v-tab key="3" href="#3" class="outlined-button">
                    Annulus
                    <v-badge
                      :color="'purple'"
                      class="mb-1 ml-1"
                      v-if="annulus_list.length > 0"
                    >
                      <template v-slot:badge>
                        {{ annulus_list.length }}
                      </template>
                    </v-badge>
                  </v-tab>
                  <v-tab key="4" href="#4" class="outlined-button">
                    Evaluations
                  </v-tab>
                  <v-tab key="5" href="#5" class="outlined-button">
                    Pressure Alerts

                    <v-badge
                      class="mb-1 ml-1"
                      :color="'orange'"
                      v-if="
                        apm_alerts.filter((elm) => elm.active == 1).length > 0
                      "
                    >
                      <template v-slot:badge>
                        {{ apm_alerts.filter((elm) => elm.active == 1).length }}
                      </template>
                    </v-badge>
                  </v-tab>
                  <v-tab key="6" href="#6" class="outlined-button">
                    Tests Status
                  </v-tab>
                  <v-tab key="7" href="#7" class="outlined-button">
                    Well Barriers
                  </v-tab>
                  <v-tabs-items v-model="tab0">
                    <v-tab-item :value="'1'">
                      <testlistform
                        :well="well"
                        :tool_list="tool_list"
                        :defaults_list="defaults_list"
                        :level_list_cor="level_list_cor"
                        :level_list_cem="level_list_cem"
                        :teststypes_list="teststypes_list"
                        :users="users"
                        :actual_corrosions="actual_corrosions"
                        :actual_cements="actual_cements"
                        :fluids_elements="fluids_elements"
                        :fluids="fluids"
                        :key="tf"
                        :tubulars="tubulars"
                        :apicasing="apicasing"
                        :chart_id="'MyChart1'"
                        :old_tab="tab2"
                        @tab_change="tab_change"
                        @well_change="well_change"
                        @corrosion_test="corrosion_test_change"
                        @cement_test="cement_test_change"
                      ></testlistform>
                    </v-tab-item>
                    <v-tab-item :value="'2'">
                      <listitems
                        :list="tubulars_list"
                        :title="'Tubulars'"
                        :headers="tubulars_headers"
                        :showedit="false"
                        :master="true"
                        :add="false"
                        :del="false"
                        :key="tbl_key"
                        @rowselect="TubularSelect"
                        @open="TubularOpen"
                        :ipg="-1"
                        :hdf="true"
                        :selitem="tubular"
                        :elevation="0"
                      >
                      </listitems>
                    </v-tab-item>
                    <v-tab-item :value="'3'">
                      <listitems
                        :list="annulus_list"
                        :title="'Annulus'"
                        :headers="annulus_headers"
                        :showedit="false"
                        :master="true"
                        :add="false"
                        :del="false"
                        :key="ann_key"
                        :ipg="-1"
                        :hdf="true"
                      >
                      </listitems>
                    </v-tab-item>
                    <v-tab-item :value="'4'">
                      <svgpipeline
                        :evaluation_list="well.wellsevaluations"
                        :key="keval"
                      ></svgpipeline>
                    </v-tab-item>
                    <v-tab-item :value="'5'">
                      <listitems
                        :list="apm_alerts"
                        :title="'Alerts'"
                        :headers="apm_alerts_headers"
                        :showedit="false"
                        :master="true"
                        :add="false"
                        :del="false"
                        :key="tbl_key"
                        @rowselect="ApmAlertSelect"
                        :ipg="-1"
                        :hdf="true"
                        @col_btn2_click="ApmAlertOpen"
                        :selitem="apm_alert"
                      >
                      </listitems>
                    </v-tab-item>
                    <v-tab-item :value="'6'">
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <listitems
                            :list="well_tests_status"
                            :title="'Tests Status'"
                            :headers="well_tests_status_headers"
                            :showedit="false"
                            :master="true"
                            :add="false"
                            :del="false"
                            :key="ts_key"
                          >
                          </listitems>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item :value="'7'">
                      <wellbarrier
                        :well="well"
                        :data="wellbarriers"
                        :key="k_barrier"
                      />
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <wellform
      :item="well"
      :key="pf"
      :showForm="!isWellClosed"
      @close="closeWellForm"
      :editer="editer"
      :valider="valider"
      :wells_types="wells_types"
      :wells_status="wells_status"
      :fields_list="fields_list"
      :suppliers="suppliers"
      :components_well="components_well"
      :tubulars="tubulars"
      :apicasing="apicasing"
      :formations="formations"
      :fluids="fluids"
      :fluids_elements="fluids_elements"
      :defaults_list="defaults_list"
      :users="users"
      :flags_list="flags_list"
      :tool_list="tool_list"
      :level_list_cor="level_list_cor"
      :level_list_cem="level_list_cem"
      :teststypes_list="teststypes_list"
      :vsizes_list="vsizes_list"
      :evaluation_list="evaluation_list"
      :fields_cor_data="fields_cor_data"
      :journals="journals"
    >
    </wellform>
    <filterform
      :showForm="!isFilterClosed"
      :components_list="components_well"
      :strings="tubulars"
      :formations="formations"
      :fluids="fluids"
      @close="closeFilterForm"
      @filter="FilterList"
    >
    </filterform>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item>
            <v-list-item-title>Corrosions</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Cements</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Details</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <waiter :showWait="showWait"> </waiter>
    <rapprt
      :well_rapport="rapport_well"
      :dialog="rapport_dialog"
      :key="krapport"
      @close="rappor_close"
    />
    <apmalert
      :showForm="!isAlertClosed"
      :item="apm_alert"
      :teststypes_list="teststypes_list"
      :tests="apm_tests"
      @close="isAlertClosed = true"
      @disabled="alert_disabled"
    >
    </apmalert>
  </v-container>
</template>

<script>
import WELLS from "../graphql/Well/WELLS.gql";
import WELL from "../graphql/Well/WELL.gql";
import ALLWELL_STATE from "../graphql/Well/ALLWELL_STATE.gql";
import ACTUAL_SITUATION from "../graphql/Well/ACTUAL_SITUATION.gql";

import FIELD_COR_DATA from "../graphql/Stat/FIELD_COR_DATA.gql";
import DATA from "../graphql/Well/DATA.gql";
import DELETE_WELL from "../graphql/Well/DELETE_WELL.gql";
import GoogleMap from "../components/GoogleMap.vue";
function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    wellform: () => import("../views/WellForm.vue"),
    filterform: () => import("../components/FilterForm.vue"),
    testlistform: () => import("../components/Test_List_Form.vue"),
    waiter: () => import("../components/Widgets/Waiter.vue"),
    rapprt: () => import("../components/RapportWell.vue"),
    svgpipeline: () => import("../components/SvgPipelineTimeline.vue"),
    apmalert: () => import("../components/ApmAlertForm.vue"),
    listitems: () => import("../components/ListItems.vue"),
    wellbarrier: () => import("../components/WellBarrier.vue"),
    GoogleMap,
  },
  props: {},
  data: () => ({
    fields_cor_data: [],
    flags_list: [],
    rapport_well: {},
    rapport_dialog: false,
    krapport: 100000,
    actual_corrosions: [],
    actual_cements: [],
    journals: [],
    viewlist: true,
    showWait: false,
    filtre: "",
    search: "",
    expire: 1,
    selitem: {},
    data_loading: false,
    loading: false,
    editer: false,
    valider: false,
    unfilter: false,
    list_key: false,
    isAlertOpen: false,
    pf: 0,
    kpa: 800,
    tool_list: [],
    defaults_list: [],
    level_list_cor: [],
    teststypes_list: [],
    level_list_cem: [],
    evaluation_list: [],
    tab0: "1",
    tubulars_list: [],
    annulus_list: [],
    apm_alerts: [],
    tbl_key: 500,
    ann_key: 600,
    keval: 4875,
    ts_key: 6000,
    apm_alert: {},
    well_tests_status_headers: [
      {
        text: "Test",
        value: "testname",
        selected: true,
        sortable: false,
      },
      {
        text: "Last Test",
        value: "testdate",
        selected: true,
        sortable: false,
      },
      {
        text: "Due date",
        value: "duedate",
        slot: "date",
        selected: true,
        sortable: false,
      },
      {
        text: "Over Due",
        value: "overdue",
        selected: true,
        sortable: false,
        slot: "chip",
        color: "color",
      },
    ],
    tubulars_headers: [
      {
        text: "Position",
        value: "no",
        selected: true,
        sortable: false,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
        width: "60",
        hiden: true,
        sortable: false,
      },
      {
        text: "Item",
        value: "item",
        selected: true,
        sortable: false,
      },
      {
        text: "Size",
        value: "api_in",

        selected: true,
        slot: "text_icon",
        icon: "mdi-dots-horizontal",
        column: "nbsizes",
        valeur: "1",
        width: "400",
        sortable: false,
      },
      {
        text: "Size (mm)",
        value: "api_mm",
        align: "center",
        selected: false,
        slot: "text_icon",
        icon: "mdi-dots-horizontal",
        column: "nbsizes",
        valeur: "1",
        width: "200",
        sortable: false,
      },
      {
        text: "Weight",
        value: "weights",
        align: "center",
        selected: false,
        slot: "text_icon",
        icon: "mdi-dots-horizontal",
        column: "nbweigths",
        valeur: "1",
        width: "180",
        sortable: false,
      },
      {
        text: "Grade",
        value: "grades",
        selected: false,
        slot: "text_icon",
        icon: "mdi-dots-horizontal",
        column: "nbgrade",
        valeur: "1",
        width: "80",
        sortable: false,
      },

      {
        text: "Depth From",
        value: "depthfrom",
        selected: true,
        slot: "cur33",
        align: "end",
        width: "100",
        dec: 0,
        sortable: false,
      },
      {
        text: "Depth To",
        value: "depthto",
        selected: true,
        slot: "cur33",
        align: "end",
        width: "100",
        dec: 0,
        sortable: false,
      },
      {
        text: "Depth",
        value: "depth",
        selected: false,
        slot: "cur33",
        align: "end",
        expire: true,
        width: "150",
        dec: 0,
        sortable: false,
      },
      {
        text: "Top of Cement",
        value: "topcement",
        selected: true,
        slot: "cur33",
        align: "end",
        dec: 0,
        sortable: false,
      },
      {
        text: "#Packer",
        value: "count_packers",
        selected: true,
        slot: "cur33",
        align: "end",
        dec: 0,
        sortable: false,
      },
      {
        text: "Collapse Resist.",
        value: "collapse",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "120",
        sortable: false,
      },
      {
        text: "IY",
        value: "iy",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "120",
        sortable: false,
      },
      {
        text: "PBY",
        value: "pby",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "120",
        sortable: false,
      },
      {
        text: "PBIY",
        value: "pbiy",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "100",
        sortable: false,
      },
      {
        text: "ID (in)",
        value: "id_in",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "100",
        sortable: false,
      },
      {
        text: "ID (mm)",
        value: "id_mm",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "100",
        sortable: false,
      },
      {
        text: "Capacity (mm)",
        value: "capacity",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "100",
        sortable: false,
      },
      {
        text: "open (mm)",
        value: "open",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "100",
        sortable: false,
      },
      {
        text: "Plugged (mm)",
        value: "plugged",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "100",
        sortable: false,
      },

      {
        text: "Details",
        value: "details",
        selected: false,
        sortable: false,
      },
    ],
    tubular: {},
    annulus_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Annulus",
        value: "annulus",
        selected: true,
      },
      {
        text: "Init. MAASP",
        value: "maasp",
        selected: true,
        slot: "cur33",
        align: "end",
        edit: true,
        type: "number",
        sm: 3,
        md: 3,
      },
      {
        text: "Calc. MAASP",
        value: "maasp_calc",
        selected: true,
        slot: "cur33",
        align: "end",
        edit: true,
        type: "number",
        sm: 3,
        md: 3,
      },
      {
        text: "MAOP",
        value: "maop",
        selected: true,
        edit: true,
        slot: "cur33",
        align: "end",
        type: "number",
        sm: 3,
        md: 3,
      },
      {
        text: "volume",
        value: "volume",
        selected: true,
        slot: "cur33",
        align: "end",
      },
      {
        text: "TOC",
        value: "topcement",
        selected: true,
        slot: "cur33",
        align: "end",
      },
      {
        text: "Fluid Type",
        value: "fluidtype",
        selected: true,
      },
      {
        text: "Fluid Density(ppg)",
        value: "fluid_density",
        selected: true,
      },
    ],
    apm_alerts_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Date/Time",
        value: "pressuredate",
        selected: true,
      },
      {
        text: "Annulus",
        value: "annulus",
        selected: true,
      },
      {
        text: "Message",
        value: "message",
        selected: true,
      },
      {
        text: "Value",
        value: "value",
        selected: true,
        slot: "cur33",
        align: "end",
      },

      {
        text: "MAASP",
        value: "maasp",
        selected: true,
        slot: "cur33",
        align: "end",
      },
      {
        text: "Alert Min",
        value: "alert_min",
        selected: true,
        slot: "cur33",
        align: "end",
      },
      {
        text: "Alert Max",
        value: "alert_max",
        selected: true,
        slot: "cur33",
        align: "end",
      },
      {
        text: "Diff(%)",
        value: "per_diff",
        slot: "progress",
        color: "color",
        selected: true,
      },
      {
        text: "Ref.",
        value: "code",
        selected: true,
      },
      {
        text: "Active",
        value: "active",
        slot: "checkbox",
        selected: true,
      },
      {
        text: "Process",
        slot: "col_btn2",
        selected: true,
        icon: "mdi-pencil",
        width: "10",
        column1: "active",
        valeur1: "1",
        sortable: false,
      },
    ],
    headers: [
      {
        text: "No",
        value: "no",
        selected: true,
        index: 0,
      },
      {
        text: "Compliant",
        value: "compliant_name",
        enum: "COMPLIANT_NAME",
        slot: "chip",
        color: "compliant_color",
        list: ["C", "NC"],
        selected: true,
        index: 1,
      },
      {
        text: "Name",
        align: "start",
        value: "wellname",
        enum: "WELLNAME",
        slot: "href",
        width: "100",
        selected: true,
        index: 2,
      },

      {
        text: "Life Cycle",
        value: "lifecyclename",
        enum: "LIFECYCLENAME",
        list: ["Drilling", "Operating", "P&A"],
        sortable: false,
        align: "left",
        selected: true,
        index: 3,
      },
      {
        text: "Drilling Date",
        value: "drillingdate",
        enum: "DRILLINGDATE",
        slot: "date",
        width: "120",
        selected: false,
        index: 4,
      },
      {
        text: "Integrety",
        value: "integrity_status_name",
        enum: "INTEGRITY_STATUS_NAME",
        list: [
          "Unknown",
          "Safe to Operate",
          "Safe to Operate Conditional",
          "Not Safe to Operate",
        ],
        slot: "chip",
        color: "integrity_status_color",
        selected: true,
        index: 5,
      },
      {
        text: "Status",
        value: "wellstatus",
        list: [],
        enum: "WELLSTATUS",
        selected: true,
        index: 6,
      },
      {
        text: "Anomaly",
        value: "anomaly",
        enum: "ANOMALY",
        slot: "chip",
        selected: true,
        index: 7,
      },
      {
        text: "Field",
        value: "fieldname",
        enum: "FIELDNAME",
        list: [],
        selected: true,
        index: 8,
      },
      {
        text: "Sector",
        value: "region",
        enum: "REGION",
        list: [],
        selected: true,
        index: 9,
      },

      {
        text: "Well Type",
        value: "welltype",
        enum: "WELLTYPE",
        list: [],
        selected: true,
        index: 10,
      },
      {
        text: "Closed Due Date",
        value: "wellduedate",
        enum: "WELLDUEDATE",
        selected: true,
        slot: "date",
        index: 11,
      },
      {
        text: "Action Due",
        value: "goreddays",
        selected: true,
        sortable: false,
        index: 12,
      },
      {
        text: "Waiver",
        value: "waiver_actif",
        enum: "DEFERAL",
        selected: true,
      },
      {
        text: "Status",
        value: "status_name",
        enum: "STATUS_NAME",
        list: ["Pending", "Valid"],
        selected: true,
        index: 14,
      },
      {
        text: "Attachments",
        value: "nbdocs",
        enum: "NBDOCS",
        slot: "col_btn3",
        selected: false,
        icon: "mdi-attachment",
        tooltip: "Attached Documents",
        sortable: false,
        width: "80",
        index: 15,
      },
      {
        text: "# Casing",
        value: "nb_casing",
        enum: "NB_CASING",
        selected: false,
        index: 16,
      },
      {
        text: "# Formations",
        value: "nb_formations",
        enum: "NB_FORMATIONS",
        selected: false,
        index: 17,
      },
      {
        text: "Repport",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-view-list",
        width: "10",
        valeur: 0,
        sortable: false,
        index: 18,
      },
    ],
    showMenu: false,
    selectedItem: 0,
    x: 0,
    y: 0,
    Qselect: {},
    Qselectall: {},
    Qdelete: {},
    Qdetaildelete: {},
    isWellClosed: true,
    isFilterClosed: true,
    isAlertClosed: true,
    apm_tests: [],
    well: {},
    klist: 0,
    tf: 1000,
    kd: 100,
    fl: 200,
    title: "",
    list: [],
    listheaders: [],
    wells_filter: null,
    cements_filter: null,
    corrosions_filter: null,
    components_filter: [],
    compliants_filter: [],
    cor_where_list: [],
    doc: {},
    wells_types: [],
    wells_status: [],
    fields_list: [],
    lifecycle: ["2"],
    suppliers: [],
    components_well: [],
    tubulars: [],
    apicasing: [],
    fluids: [],
    fluids_elements: [],
    formations: [],
    users: [],
    new_item_code: "",
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    vsizes_list: [],
    tab2: null,
    well_tests_status: [],
    wellbarriers: [],
    k_barrier: 50000,
  }),

  computed: {},
  watch: {
    search: debounce(function () {
      this.expire = this.search ? this.expire3 : this.expire1;
    }, 1000),
    "$store.state.filtre": {
      handler() {
        this.filtre = this.$store.state.filtre;
        this.klist++;
      },
    },
    "$store.state.fields": {
      handler() {
        this.klist++;
      },
    },
  },

  async created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.klist++;
    }
    if (this.$route.params.well) {
      this.OpenWellForm(this.$route.params.well);
    }
    let r = await this.$requette(FIELD_COR_DATA);
    if (r.ok) {
      this.fields_cor_data = r.data.FieldCorData;
    }
    this.tubulars_headers[7].text =
      "Depth From (" + this.$store.state.options[0].depth + ")";
    this.tubulars_headers[8].text =
      "Depth To (" + this.$store.state.options[0].depth + ")";
    this.tubulars_headers[9].text =
      "Depth (" + this.$store.state.options[0].depth + ")";
    this.tubulars_headers[10].text =
      "Top of Cement (" + this.$store.state.options[0].depth + ")";
    this.tubulars_headers[12].text =
      "Collapse Resist. (" + this.$store.state.options[0].pressure + ")";
    this.tubulars_headers[13].text =
      "IY (" + this.$store.state.options[0].pressure + ")";
    this.tubulars_headers[14].text =
      "PBY (" + this.$store.state.options[0].weight + ")";
    this.tubulars_headers[15].text =
      "PBIY (" + this.$store.state.options[0].pressure + ")";
    this.tubulars_headers[18].text =
      "Capacity (" + this.$store.state.options[0].volume + ")";
    this.tubulars_headers[19].text =
      "Open (" + this.$store.state.options[0].volume + ")";
    this.tubulars_headers[20].text =
      "Plugged (" + this.$store.state.options[0].volume + ")";
    this.annulus_headers[2].text =
      "Init. MAASP (" + this.$store.state.options[0].pressure + ")";
    this.annulus_headers[3].text =
      "Calc. MAASP (" + this.$store.state.options[0].pressure + ")";
    this.annulus_headers[4].text =
      "MAOP (" + this.$store.state.options[0].pressure + ")";
    this.annulus_headers[5].text =
      "Volume (" + this.$store.state.options[0].volume + ")";
    this.annulus_headers[6].text =
      "Top of Cement (" + this.$store.state.options[0].depth + ")";
    this.keval++;
  },

  async mounted() {
    this.Qdelete = DELETE_WELL;
    this.Qselect = WELLS;
    this.Qselectall = ALLWELL_STATE;

    this.editer =
      this.$store.state.auth.includes("02001") || this.$store.state.isadmin;
    this.valider =
      this.$store.state.auth.includes("03001") || this.$store.state.isadmin;
    this.getdata();
  },

  methods: {
    async well_change() {
      let r3 = await this.$requette(
        WELL,
        {
          TypeScope: parseInt(this.well.id),
          CatScope: this.$store.state.fields,
        },
        "no-cache"
      );
      this.data_loading = true;
      if (r3.ok) {
        if (r3.data.allwellsstates.length > 0)
          this.well = r3.data.allwellsstates[0];

        this.tbl_key++;
        this.ann_key++;
        this.frm_key++;
        this.cmp_key++;
        this.keval++;
        this.$nextTick(() => {
          this.change = 2;
          this.ksvg1++;
        });
      }
      this.data_loading = false;
    },
    alert_disabled(item) {
      let i = this.apm_alerts.findIndex((elm) => elm.id == item.id);
      if (i >= 0) {
        this.apm_alerts[i].active = 0;
        this.apm_alerts[i].color = "green";
      }
    },
    ApmAlertSelect(apm_alert) {
      this.apm_alert = apm_alert;
    },
    ApmAlertOpen(apm_alert) {
      this.apm_alert = apm_alert;
      this.apm_tests = apm_alert.tests;
      this.isAlertClosed = false;
      this.ka++;
    },
    corrosion_test_change(cor) {
      this.corrosion_test = cor;
      this.ksvg1++;
    },
    cement_test_change(cem) {
      this.cement_test = cem;
      this.ksvg1++;
    },
    rappor_close() {
      this.rapport_dialog = false;
    },
    WellFailuresOpen(item) {
      this.rapport_well = item;
      this.rapport_dialog = true;
      this.krapport++;
    },
    TubularSelect(item) {
      if (item) {
        this.tubular = item;
        this.tc_key++;
      }
    },

    TubularOpen(item) {
      this.annulus = false;
      this.tubular = item;
      this.tubular.well_id = this.well.id;
      this.tkey++;
    },
    async getdata() {
      let r = await this.$requette(DATA);
      if (r.ok) {
        this.journals = r.data.journals;
        this.flags_list = r.data.flag_list;
        this.vsizes_list = r.data.vsizes_list;
        this.formations = r.data.formations;
        this.wells_types = r.data.wellstypes;
        this.wells_status = r.data.wellsstatus;
        this.fields_list = r.data.fields_list;
        this.suppliers = r.data.suppliers;
        this.components_well = r.data.wellstypes_components;
        this.tubulars = r.data.tubulars;
        this.apicasing = r.data.apicasing;
        this.fluids = r.data.fluids;
        this.users = r.data.users;
        this.tool_list = r.data.toolslist;
        this.level_list_cor = r.data.level_list;
        this.defaults_list = r.data.defaults_list;
        this.teststypes_list = r.data.teststypes_list;
        this.level_list_cem = r.data.level_cement_list;
        this.fluids_elements = r.data.elements_list;
        this.evaluation_list = r.data.evaluations;
        this.headers[6].list = this.wells_status.map((elm) => elm.label);

        this.headers[8].list = this.fields_list
          .filter((elm) => elm.isfield)
          .map((elm) => elm.description);

        this.headers[9].list = this.fields_list
          .filter((elm) => !elm.isfield && elm.childcount == 0)
          .map((elm) => elm.description);
        this.headers[10].list = this.wells_types.map((elm) => elm.label);
      } else this.snack(r.error);
    },
    async WellChange(item) {
      if (item) this.well = item;
      this.selitem = item;
      if (item.id) {
        this.data_loading = true;
        let r = await this.$requette(WELL, {
          TypeScope: parseInt(item.id),
          CatScope: this.$store.state.fields,
        });
        if (r.ok) {
          this.data_loading = false;
          if (r.data.allwellsstates.length > 0)
            this.well = r.data.allwellsstates[0];
          this.tubulars_list = this.well.wellstubulars
            ? this.well.wellstubulars.filter((elm) => elm.active == 1)
            : [];
          this.annulus_list = this.well.wellstubulars
            ? this.well.wellstubulars.filter(
                (elm) => elm.depthfrom == 0 && elm.active == 1
              )
            : [];
          this.apm_alerts = this.well.apm_alerts ? this.well.apm_alerts : [];
          r = await this.$requette(ACTUAL_SITUATION, {
            well_id: [this.well.id],
            well: this.well.id,
          });
          if (r.ok) {
            this.actual_corrosions = r.data.actual_corrosions;
            this.actual_cements = r.data.actual_cements;
            this.well_tests_status = r.data.well_tests_status;
            this.wellbarriers = r.data.well_barriers;
            this.k_barrier++;
            this.ksvg1++;
          }
          this.tf++;
          this.tbl_key++;
          this.ann_key++;
          this.ts_key++;
          this.keval++;
        }
        this.data_loading = false;
      }
    },
    tab_change(tab) {
      this.tab2 = tab;
    },
    async OpenWellForm(item) {
      if (item.id < 0) {
        this.well = item;
        this.isWellClosed = false;
        this.pf++;
      } else {
        this.showWait = true;
        let r = await this.$requette(
          WELL,
          {
            TypeScope: parseInt(item.id),
            CatScope: this.$store.state.fields,
          },
          "no-cache"
        );
        if (r.ok) {
          if (r.data.allwellsstates.length > 0)
            this.well = r.data.allwellsstates[0];

          this.isWellClosed = false;
          this.pf++;
        } else this.snack(r.error);
        this.showWait = false;
      }
    },
    contextmenu(e, item) {
      this.document = item;
      this.selitem = item;
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    snack(t) {
      this.snackbar = true;
      this.snackbar_color = "error";
      this.snackbar_text = t;
    },
    closeWellForm(item) {
      this.selitem = item;
      this.isWellClosed = true;
    },
    OpenFilterForm() {
      this.isFilterClosed = false;
    },
    FilterList(components_filter, compliants_filter, filter) {
      this.wells_filter = JSON.stringify(filter);
      this.compliants_filter = compliants_filter;
      this.components_filter = components_filter;

      this.unfilter = true;
      this.klist++;
      this.isFilterClosed = true;
    },
    closeFilterForm() {
      this.isFilterClosed = true;
    },

    unfiltrer() {
      this.unfilter = false;
      this.corrosions_filter = null;
      this.cements_filter = null;
      this.wells_filter = null;
      this.$store.dispatch("Changed", true);
    },
    async changeview() {
      this.viewlist = !this.viewlist;
    },
    updatefiltre() {
      this.klist++;
      this.list_key = !this.list_key;
    },
  },
};
</script>
<style>
div.my-tabs [role="tab"] {
  justify-content: flex-start;
}

.outlined-button {
  background-color: transparent; /* No background */
  border: 2px solid; /* Blue border to mimic outlined button */

  margin: 2px;
  transition: all 0.3s ease; /* Smooth hover transition */
}

.stickycard {
  margin-top: -60px;
  justify-content: center;
}

.outlined-button:hover {
  background-color: rgba(
    25,
    118,
    210,
    0.1
  ); /* Light blue background on hover */
}
</style>
